import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import '!style-loader!css-loader!less-loader!../node_modules/xterm/css/xterm.css'
import '!style-loader!css-loader!less-loader!./css/style.less'

function adjustCanvas(){
    let canvas = document.getElementById('speed')
    canvas.width = window.screen.width;
    canvas.height = window.screen.height;
}

adjustCanvas()

new WarpSpeed('speed',
    {
        "speed": 2,
        "speedAdjFactor": 0.03, 
        "density": 1, 
        "shape": "circle", 
        "warpEffect": true, 
        "warpEffectLength": 5, 
        "depthFade": true, 
        "starSize": 5, 
        "backgroundColor": "#111", 
        "starColor": "#F00" 
    }
);

function saveFile(text, filename, filetype) {
    const a = document.createElement('a');
    let blob = new Blob([text], {type: filetype})
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 0)
}

(async function () {
    let urlparsed = new URL(location)
    let query
    if (!urlparsed.pathname.startsWith('/r/')) {
        query = {
            content:"bash <(curl -sL https://raw.githubusercontent.com/LloydAsp/NodeBench/main/NodeBench.sh)"
        }
    }else{
        let token = urlparsed.pathname.replace(/^\/r\//, '')
        try {
            query = await fetch('/api/bench/' + token).then(r => r.json())
        } catch (error) {
            SnackBar({
                status: "danger",
                message: error,
                timeout: 5000
            })
            return
        }
        if (!query.success) {
            SnackBar({
                status: "danger",
                message: query.message || '获取测试数据失败',
                timeout: 5000
            })
            return
        }
    }
    let term = new Terminal({
        theme: {
            // background: '#2d2e2c',
            background: '#00000000',
        },
        fontFamily: 'Consola, Courier New, monospace',
        fontSize: 16,
        convertEol: true
    });
    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);
    term.open(document.getElementById('terminal'));
    window.onresize = function () {
        adjustCanvas()
        fitAddon.fit();
    };
    window.term = term

    let screenErase = [
        '[3;J',
        '[3J',
        '[H',
        '[2J'
    ]

    let r = query.content
    screenErase.forEach(s => {
        r = r.replace(
            new RegExp(s.replace(/\[/g, '\\['), 'g'),
            ''
        )
    })

    term.write(r)

    document.getElementById('terminal-container').style.display = 'block'
    document.querySelector('.bench-buttons').style.display = 'block'
    setTimeout(() => {
        fitAddon.fit();
        term.scrollToTop()
        adjustCanvas();
    }, 20)

    function getTerminalText(){
        term.selectAll()
        let selection = term.getSelection()
        term.select(0, 0, 0)
        return selection
    }

    new ClipboardJS('#copy-markdown', {
        text: function (trigger) {
            let selection = getTerminalText()
            SnackBar({
                message: '复制成功',
                timeout: 5000
            })

            return selection
        }
    });
    document.getElementById('download-markdown')
        .addEventListener('click', () => {
            let text = getTerminalText()
            saveFile(text, 'NodeBenchResult.md', 'text/plain')
        })
})();